import { menuTopSelector, hasSmartBannerSelector } from '@/store';
import { useSelector } from 'react-redux';
import { memo } from 'react';
import { ActiveLink } from '@/components';
import clsx from 'clsx';

import styles from './NavPopular.module.scss';

const NavPopular = memo(() => {
	const top = useSelector(menuTopSelector);
	const hasSmartBanner = useSelector(hasSmartBannerSelector);
	const isSaleSlug = slug => slug && slug.toLowerCase().includes('sale');

	return (
		<div className={clsx(styles.container, hasSmartBanner && styles.smartBanner)}>
			<ul className={styles.list}>
				{top.map(item => {
					const { name, slug, id } = item;

					return (
						<li className={styles.item} key={id}>
							<ActiveLink
								href={`${slug}`}
								as={`${slug}`}
								activeClassName={clsx(
									styles.link,
									isSaleSlug(slug) && styles.sale,
									styles.activeLink,
								)}
							>
								<a className={clsx(styles.link, isSaleSlug(slug) && styles.sale)}>{name}</a>
							</ActiveLink>
						</li>
					);
				})}
			</ul>
		</div>
	);
});

export default NavPopular;
