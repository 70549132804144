import ArrowDown from './ArrowDownIcon';
import ArrowUp from './ArrowUpIcon';
import ArrowRightGallery from './ArrowRightGalleryIcon';
import ArrowLeftGallery from './ArrowLeftGalleryIcon';
import ArrowLeft from './ArrowLeftIcon';
import ArrowRight from './ArrowRightIcon';
import Blank from './BlankIcon';
import Basket from './Basket';
import Car from './CarIcon';
import CarIconNew from './CarIconNew';
import Card from './CardIcon';
import Cart from './CartIcon';
import Cash from './CashIcon';
import CircleError from './CircleErrorIcon';
import CircleQuestion from './CircleQuestionIcon';
import CircleSuccess from './CircleSuccessIcon';
import CircleWarn from './CircleWarnIcon';
import CircleWait from './CircleWaitIcon';
import Close from './Close';
import CloseGallery from './CloseGalleryIcon';
import Certificate from './CertificateIcon';
import Checked from './CheckedIcon';
import Danger from './DangerIcon';
import Delivery from './DeliveryIcon';
import DeliveryFast from './DeliveryFastIcon';
import Edit from './EditIcon';
import Envelope from './EnvelopeIcon';
import Exchange from './Exchange';
import Facebook from './FacebookIcon';
import Favorite from './FavoriteIcon';
import Filter from './FilterIcon';
import GooglePlay from './GooglePlay';
import InputSuccess from './InputSuccessIcon';
import Giftcard from './Giftcard';
import Insta from './InstaIcon';
import IOS from './IOS';
import Heart from './HeartIcon';
import Lamp from './Lamp';
import Login from './LoginIcon';
import Mail from './MailIcon';
import MapIcon from './MapIcon';
import MapPin from './MapPinIcon';
import MArrowUp from './MArrowUpIcon';
import MapPoint from './MapPointIcon';
import MArrowLeft from './MArrowLeftIcon';
import MArrowRight from './MArrowRightIcon';
import Minus from './MinusIcon';
import Pin from './PinIcon';
import Phone from './PhoneIcon';
import Plus from './PlusIcon';
import PlusImage from './PlusImageIcon';
import PhoneFill from './PhoneIconFill';
import PhoneFillContacts from './PhoneIconFillContacts';
import Products from './ProductsIcon';
import Remove from './RemoveIcon';
import RemoveImage from './RemoveImageIcon';
import RemoveProduct from './RemoveProductIcon';
import Search from './SearchIcon';
import ShopMap from './ShopMapIcon';
import Shirt from './Shirt';
import Sort from './SortIcon';
import Spinner from './SpinnerIcon';
import Success from './SuccessIcon';
import ThreeRectLoader from './ThreeRectLoader';
import Tick from './TickIcon';
import Question from './QuestionIcon';
import QuestionCurrency from './QuestionCurrencyIcon';
import User from './UserIcon';
import VK from './VKIcon';
import Play from './Play';
import Present from './PresentIcon';
import Pinterest from './PinterestIcon';
import LogoLanding from './LogoLanding';
import Promocode from './Promocode';
import Viber from './ViberIcon';
import Telegram from './TelegramIcon';
import WA from './WAIcon';

const Icon = props => {
	switch (props.name) {
		case 'arrow-up':
			return <ArrowUp {...props} />;
		case 'arrow-down':
			return <ArrowDown {...props} />;
		case 'arrow-left':
			return <ArrowLeft {...props} />;
		case 'arrow-right':
			return <ArrowRight {...props} />;
		case 'arrow-right-gallery':
			return <ArrowRightGallery {...props} />;
		case 'arrow-left-gallery':
			return <ArrowLeftGallery {...props} />;
		case 'blank':
			return <Blank {...props} />;
		case 'basket':
			return <Basket {...props} />;
		case 'car':
			return <Car {...props} />;
		case 'car-new':
			return <CarIconNew {...props} />;
		case 'card':
			return <Card {...props} />;
		case 'cash':
			return <Cash {...props} />;
		case 'cart':
			return <Cart {...props} />;
		case 'certificate':
			return <Certificate {...props} />;
		case 'checked':
			return <Checked {...props} />;
		case 'circle-success':
			return <CircleSuccess {...props} />;
		case 'circle-error':
			return <CircleError {...props} />;
		case 'circle-question':
			return <CircleQuestion {...props} />;
		case 'circle-wait':
			return <CircleWait {...props} />;
		case 'circle-warn':
			return <CircleWarn {...props} />;
		case 'close':
			return <Close {...props} />;
		case 'close-gallery':
			return <CloseGallery {...props} />;
		case 'delivery':
			return <Delivery {...props} />;
		case 'danger':
			return <Danger {...props} />;
		case 'delivery-fast':
			return <DeliveryFast {...props} />;
		case 'edit':
			return <Edit {...props} />;
		case 'exchange':
			return <Exchange {...props} />;
		case 'envelope':
			return <Envelope {...props} />;
		case 'favorite':
			return <Favorite {...props} />;
		case 'fb':
			return <Facebook {...props} />;
		case 'pinterest':
			return <Pinterest {...props} />;
		case 'filter':
			return <Filter {...props} />;
		case 'google-play':
			return <GooglePlay {...props} />;
		case 'input-success':
			return <InputSuccess {...props} />;
		case 'giftcard':
			return <Giftcard {...props} />;
		case 'insta':
			return <Insta {...props} />;
		case 'ios':
			return <IOS {...props} />;
		case 'heart':
			return <Heart {...props} />;
		case 'lamp':
			return <Lamp {...props} />;
		case 'login':
			return <Login {...props} />;
		case 'mail':
			return <Mail {...props} />;
		case 'minus':
			return <Minus {...props} />;
		case 'map':
			return <MapIcon {...props} />;
		case 'map-pin':
			return <MapPin {...props} />;
		case 'm-arrow-up':
			return <MArrowUp {...props} />;
		case 'map-point':
			return <MapPoint {...props} />;
		case 'm-arrow-left':
			return <MArrowLeft {...props} />;
		case 'm-arrow-right':
			return <MArrowRight {...props} />;
		case 'plus':
			return <Plus {...props} />;
		case 'pin':
			return <Pin {...props} />;
		case 'phone':
			return <Phone {...props} />;
		case 'plus':
			return <Plus {...props} />;
		case 'plus-image':
			return <PlusImage {...props} />;
		case 'phone-fill':
			return <PhoneFill {...props} />;
		case 'phone-fill-contacts':
			return <PhoneFillContacts {...props} />;
		case 'products':
			return <Products {...props} />;
		case 'remove':
			return <Remove {...props} />;
		case 'remove-image':
			return <RemoveImage {...props} />;
		case 'remove-product':
			return <RemoveProduct {...props} />;
		case 'search':
			return <Search {...props} />;
		case 'shop-map':
			return <ShopMap {...props} />;
		case 'sort':
			return <Sort {...props} />;
		case 'shirt':
			return <Shirt {...props} />;
		case 'spinner':
			return <Spinner {...props} />;
		case 'success':
			return <Success {...props} />;
		case 'telegram':
			return <Telegram {...props} />;
		case 'three-rect-loader':
			return <ThreeRectLoader {...props} />;
		case 'tick':
			return <Tick {...props} />;
		case 'question':
			return <Question {...props} />;
		case 'question-currency':
			return <QuestionCurrency {...props} />;
		case 'user':
			return <User {...props} />;
		case 'vk':
			return <VK {...props} />;
		case 'play':
			return <Play {...props} />;
		case 'present':
			return <Present {...props} />;
		case 'LogoLanding':
			return <LogoLanding {...props} />;
		case 'promocode':
			return <Promocode {...props} />;
		case 'viber':
			return <Viber {...props} />;
		case 'wa':
			return <WA {...props} />;
		default:
			return null;
	}
};

export default Icon;
