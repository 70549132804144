import React, { useState, useEffect, useCallback, useMemo } from 'react';
import dynamic from 'next/dynamic';
import { login, sendCode } from '@fh-components/fh-js-api-core/store/slices/userSlice';
import { resetCheckoutData } from '@fh-components/fh-js-api-core/store/slices/checkoutSlice';
import { fetchCart } from '@fh-components/fh-js-api-core/store/slices/cartSlice';
import { userErrorSelector, redirectUrlSelector } from '@/store';
import { useSelector, useDispatch } from 'react-redux';
import Router from 'next/router';
import { nanoid } from 'nanoid';
import Cookie from 'js-cookie';
import ecommerce from '@/seo/Ecommerce';
import { PhoneForm, CodeForm } from '@/containers/login';
import { useNotification } from '@/contexts/NotificationContext';
import { Button, Loader } from '@/components';

import styles from './loginForm.module.scss';

const PersonalDataModal = dynamic(() => import('../../../components/personalDataModal'), {
	loading: () => <Loader />,
});

const PHONE_REPLACE_MASK = /[^\d]+/g;

const Login = () => {
	const error = useSelector(userErrorSelector);
	const redirectUrl = useSelector(redirectUrlSelector);
	const errors = useMemo(() => error?.errors ?? {}, [error?.errors]);

	const [state, setState] = useState({ phone: '', code: '' });
	const [screen, setScreen] = useState('phone');
	const [isPersonalDataModalShow, setIsPersonalDataModalShow] = useState(false);

	const notification = useNotification();
	const dispatch = useDispatch();

	useEffect(() => {
		const phoneError = errors?.phone ?? null;
		const codeError = errors?.verification_code ?? null;

		if (phoneError) {
			setScreen('phone');
		}

		if (codeError) {
			setScreen('code');
		}
	}, [errors]);

	const onPhoneSubmit = async ({ phone }) => {
		const phoneNumbers = phone.replace(PHONE_REPLACE_MASK, '');
		setState(prev => ({ ...prev, phone }));
		await dispatch(
			sendCode({
				phone: phoneNumbers,
				onSuccess: () => {
					// ecommerce.onConfirmPhoneNumber();
					setScreen('code');
				},
			}),
		);
	};

	const onPhoneChange = () => {
		setScreen('phone');
	};

	const onCodeResend = async () => {
		const phoneNumbers = state.phone.replace(PHONE_REPLACE_MASK, '');
		await dispatch(sendCode({ phone: phoneNumbers }));
	};

	const addNotification = useCallback(() => {
		const id = `login-products-${nanoid()}`;
		const handleClick = () => {
			notification.close(id);
		};

		notification.add({
			id,
			style: 'warning',
			content: (
				<>
					<p>Мы объединили товары в избранном и корзине со всех ваших устройств.</p>
					<Button
						action='button'
						onClick={handleClick}
						classNames={{ general: styles['combine-products-btn'] }}
					>
						Понятно
					</Button>
				</>
			),
			closable: true,
		});
	}, [notification]);

	const redirect = async () => {
		if (redirectUrl.href === '/cart') {
			await dispatch(fetchCart());
		}

		const { href, asPath, query } = redirectUrl;

		Router.push({ pathname: href, query }, { pathname: asPath, query });
	};

	const onCodeSubmit = async ({ code }) => {
		const phoneNumbers = state.phone.replace(PHONE_REPLACE_MASK, '');
		const mindboxDeviceUUID = Cookie.get('mindboxDeviceUUID');
		await dispatch(
			login({
				phone: phoneNumbers,
				verification_code: code,
				mindboxDeviceUUID,
				onSuccess: async ({ hasNewProducts, isNewUser, user }) => {
					dispatch(resetCheckoutData());

					ecommerce.setLogin(isNewUser ? 'sign_up' : 'login', user.id);

					if (hasNewProducts) {
						addNotification();
					}

					if (window && window.generalPixel) {
						window.generalPixel.postClick('a2');
					}
					if (!user?.is_personal_data) {
						setIsPersonalDataModalShow(true);
					} else {
						redirect();
					}
				},
			}),
		);
	};

	return screen === 'phone' ? (
		<>
			<h2 className={styles['form-title']}>Введите номер телефона для входа</h2>
			<PhoneForm onPhoneSubmit={onPhoneSubmit} />
		</>
	) : (
		<>
			<PersonalDataModal
				showDialog={isPersonalDataModalShow}
				onDismiss={() => {
					setIsPersonalDataModalShow(false);
				}}
				onShow={() => {
					setIsPersonalDataModalShow(true);
				}}
				redirect={redirect}
			/>

			<h2 className={styles['form-title']}>Введите код из SMS</h2>

			<div className={styles['form-text']}>
				<p>Мы отправили код подтверждения на номер:</p>
				<p>
					{state.phone}
					<Button
						type='link'
						action='button'
						onClick={onPhoneChange}
						classNames={{ general: styles['change-btn'] }}
					>
						Изменить
					</Button>
				</p>
			</div>

			<CodeForm onCodeSubmit={onCodeSubmit} onCodeResend={onCodeResend} />
		</>
	);
};

export default Login;
