import { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import styles from './SelectBase.module.scss';

const SelectBase = ({
	title,
	selected = false,
	handleClear = () => {},
	children = [],
	closeOnClick = false,
	disabled = false,
	onOpen,
}) => {
	const wrapper = useRef(null);
	const [open, setOpen] = useState(false);
	const onClickOutside = e => {
		if (closeOnClick) {
			if (open) {
				setOpen(!open);
			}
			return;
		}
		if ((!wrapper?.current?.contains(e.target) || wrapper.current === e.target) && open) {
			setOpen(!open);
		}
	};

	useEffect(() => {
		if (open) {
			document.addEventListener('click', onClickOutside);
		}

		if (onOpen) {
			onOpen(open);
		}

		return () => {
			if (open) {
				document.removeEventListener('click', onClickOutside);
			}
		};
	}, [open, onClickOutside, onOpen]);

	return (
		<div className={styles.select} ref={wrapper}>
			<div
				className={clsx({
					[styles['head']]: true,
					[styles['headHover']]: !disabled,
					[styles['selected']]: selected && !disabled,
					[styles['open']]: open,
					[styles['disabled']]: disabled,
				})}
				onClick={() => {
					if (!disabled) {
						setOpen(!open);
					}
				}}
			>
				{title}
				<button
					className={clsx(styles.clear, selected && !disabled && styles.visibleClear)}
					onClick={handleClear}
				/>
			</div>

			<div className={clsx(styles.body, open && styles.visibleBody)}>
				<div className={styles.content}>{children}</div>
			</div>
		</div>
	);
};

export default SelectBase;
