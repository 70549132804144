import { useRouter } from 'next/router';
import { decode } from 'he';
import Head from 'next/head';
import { useHost } from '@/contexts/HostContext';
import { getCorrectName } from '@/utils';

const getArticleMetaData = data => {
	const publishedDate = data?.published_at ?? null;
	const modifiedDate = data?.modified_at ?? null;
	const type = data?.type;

	return (
		<>
			<meta property='article:author' content=' ' />
			{publishedDate && <meta property='published_time' content={publishedDate} />}
			{modifiedDate && <meta property='modified_time' content={modifiedDate} />}
			<meta property='article:section' content={type === 'article' ? 'Новости' : 'Акции'} />
		</>
	);
};

const getProductMetaData = data => {
	const sku = data?.sku ?? null;
	const price = data?.old_price ?? null;
	const onSale = data?.old_price !== data?.price;
	const salePrice = data?.price;
	const brand = data?.brand;
	const inStock = data?.quantity !== 0;
	const breadCrumbs = data?.breadcrumbs ?? [];
	const category = breadCrumbs?.[breadCrumbs.length - 2]?.name ?? null;
	let currency = 'BYN';
	if (typeof window !== 'undefined' && localStorage.getItem('currency') === 'RU') {
		currency = '₽';
	}

	return (
		<>
			{sku && <meta property='product:retailer_part_no' content={sku} />}
			{category && <meta property='product:category' content={category} />}
			{price && <meta property='product:price:amount' content={price} />}
			{onSale && <meta property='product:sale_price:amount' content={salePrice} />}
			<meta property='product:price:currency' content={currency} />
			{brand && <meta property='product:brand' content={brand} />}
			<meta property='product:availability' content={inStock ? 'В наличии' : 'Нет в наличии'} />
		</>
	);
};

const MetaData = ({
	title: titleFromProps,
	description: descriptionFromProps,
	seo = {},
	ogTitle: ogTitleFromProps,
	ogDescription: ogDescriptionFromProps,
	image,
	imageSmall,
	withPage = false,
	type,
	articleData,
	productData,
	robotsContent,
}) => {
	const host = useHost();
	const origin = `https://${host}`;

	const router = useRouter();
	const path = router.asPath.split('?')[0];
	const url = `${origin}${path}`;

	const defaultImages = {
		rect: `${origin}/images/og_image_rect.webp`,
		square: `${origin}/images/og_image_sq.webp`,
	};

	const { rect: defaultImageRect, square: defaultImageSquare } = defaultImages;

	let title = titleFromProps || 'FH.BY';
	let description = descriptionFromProps || 'Магазины модной одежды';
	let ogTitle = ogTitleFromProps || title;
	let ogDescription = ogDescriptionFromProps || description;

	if (withPage) {
		const page = router.query.page || '1';
		if (+page !== 1) {
			title = `${title} | Страница ${page}`;
			ogTitle = `${ogTitle} | Страница ${page}`;
			description = `${description} | Страница ${page}`;
			ogDescription = `${ogDescription} | Страница ${page}`;
		}
	}

	const imageRect = image || defaultImageRect;
	const imageSquare = imageSmall || defaultImageSquare;

	const decodedSeoTitle = decode(seo && seo.title ? getCorrectName(seo.title) : title);

	return (
		<Head>
			<title>{decodedSeoTitle}</title>
			<link rel='canonical' href={url} />
			<meta property='og:title' content={ogTitle} />
			<meta name='twitter:title' content={ogTitle} />
			<meta property='og:type' content={type || 'website'} />
			<meta property='og:site_name' content='fh.by' />
			<meta property='og:url' content={url} />
			<meta property='og:locale' content='ru_RU' />
			<meta
				name='description'
				content={seo && seo.description ? getCorrectName(seo.description) : description}
			/>
			<meta property='og:description' content={ogDescription} />
			<meta name='twitter:description' content={ogDescription} />

			<meta property='og:image' content={imageRect} />
			<meta property='og:image:secure_url' content={imageRect} />
			<meta property='og:image:width' content='1200' />
			<meta property='og:image:height' content='630' />
			<meta property='og:image:type' content='image/jpeg' />

			<meta property='og:image' content={imageSquare} />
			<meta property='og:image:secure_url' content={imageSquare} />
			<meta property='og:image:width' content='300' />
			<meta property='og:image:height' content='300' />
			<meta property='og:image:type' content='image/jpeg' />
			<meta name='twitter:card' content='summary' />
			<meta property='twitter:image' content={imageSquare} />

			{articleData && getArticleMetaData(articleData)}
			{productData && getProductMetaData(productData)}

			{robotsContent && <meta name='robots' content={robotsContent} />}
		</Head>
	);
};

export default MetaData;
