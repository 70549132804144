import { useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import styles from './ProductFeature.module.scss';
import useWindowWidth from '@/lib/hooks/useWindowWidth';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import vars from '@/assets/styles/vars';

const PurpleTippy = styled(Tippy)`
	.tippy-arrow {
		width: 16px;
		height: 16px;
	}

	&[data-placement^='bottom'] > .tippy-arrow {
		top: -12px;
		border-bottom: 11px solid white;
		border-top: none;
	}
`;

const mobileBreakPoint = vars.breakpoints.lMobile;

const style = {
	color: '#585858',
	backgroundColor: 'white',
	whiteSpace: 'pre-wrap',
	boxShadow: '0px 6px 20px rgb(0 0 0 / 8%)',
	borderRadius: 18,
	padding: '12px 15px',
	':after': {
		content: '',
		color: 'red',
		backgroundColor: 'red',
		borderTop: '10px solid red',
		borderLeft: '10px solid transparent',
		borderRight: '10px solid transparent',
	},
};

const ProductFeature = ({ title, styledType, text = '' }) => {
	const windowWidth = useWindowWidth();
	const [visible, setVisible] = useState(false);
	const show = () => setVisible(true);
	const hide = () => setVisible(false);

	const handleClick = useCallback(e => {
		e.stopPropagation();
		e.preventDefault();
		show();
	}, []);

	useEffect(() => {
		if (windowWidth < mobileBreakPoint) {
			document.addEventListener('touchstart', hide);
			return () => {
				document.removeEventListener('touchstart', hide);
			};
		}
	}, [windowWidth]);

	const content = useCallback(
		isMobile => (
			<div
				className={clsx(
					{
						[styles.text]: styledType === 'text',
						[styles.new]: styledType === 'new',
						[styles.sale]: styledType === 'sale',
						[styles.alternative]: styledType === 'alternative',
					},
					styles.productFeature,
				)}
				onClick={isMobile ? handleClick : null}
			>
				<p className={styles.title}>{title}</p>
			</div>
		),
		[title, styledType, handleClick],
	);

	if (!text || text.length === 0) {
		return content();
	}

	return windowWidth > mobileBreakPoint ? (
		<PurpleTippy content={<div style={style}>{text}</div>}>{content(false)}</PurpleTippy>
	) : (
		<PurpleTippy visible={visible} onClickOutside={hide} content={<div style={style}>{text}</div>}>
			{content(true)}
		</PurpleTippy>
	);
};

export default ProductFeature;
