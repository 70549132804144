import { useEffect, useState } from 'react';
import {
	createSession,
	createToken,
} from '@fh-components/fh-js-api-core/store/slices/sessionSlice';
import {
	isSessionLoadedSelector,
	sessionTokenSelector,
	userSelector,
	hasGotIPSelector,
} from '@/store';
import { fetchCurrency } from '@fh-components/fh-js-api-core/store/slices/currencySlice';
import { getIP } from '@fh-components/fh-js-api-core/store/slices/userSlice';
import ecommerce from '@/seo/Ecommerce';
import { fetchMenu } from '@fh-components/fh-js-api-core/store/slices/menuSlice';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import Cookie from 'js-cookie';
import { nanoid } from 'nanoid';
import DeviceDetector from 'device-detector-js';
import { createStore } from '@fh-components/fh-js-api-core/store';
import Router from 'next/router';
import App from 'next/app';
import getConfig from 'next/config';
import { NotificationProvider } from '@/contexts/NotificationContext';
import { Page, Scripts } from '@/layouts';
import { HostProvider } from '@/contexts/HostContext';
import { NavProvider } from '@/contexts/NavContext';
import { ChannelsProvider } from '@/contexts/ChannelsContext';
import { EventsProvider } from '@/contexts/EventsContext';
import parseCookies from '@/lib/parseCookies';
import theme from '@/assets/styles/theme';
import YM from '@/seo/YM';
import mindbox from '@/seo/Mindbox';
import ErrorBoundary from '@/containers/errorBoundary';
import { Head } from '@/containers';
import getHost from '@/lib/getHost';
import { settingGlobalParams } from '@/utils/settingGlobalParams';

import Error from './_error/index';

import '@/assets/styles/global.scss';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';

// npx prettier --write "**/*.{js,jsx,scss, json}"

const deviceDetector = new DeviceDetector();

const requestIp = require('request-ip');

const { publicRuntimeConfig } = getConfig();

const isClient = () => typeof window !== 'undefined';

const isServer = () => !isClient();

const isProductionMode = process.env.NODE_ENV === 'production';

Router.events.on('routeChangeComplete', url => {
	if (isProductionMode) {
		YM.pageview(url);
	}

	if (isClient() && mindbox) {
		mindbox?.webpushSubscribe();
	}
});

const getStore = (() => {
	let store = null;

	const tokenGenerator = () => ({
		source: 'web',
		token: nanoid() + `${new Date().getTime()}`,
	});

	return ({ initialState, req, res, deviceType = 'web-desktop' } = {}) => {
		if (isServer()) {
			return createStore(initialState, {
				baseUrl: publicRuntimeConfig.apiUrl,
				deviceType,
				isMobile: false,
				storage: {
					get: name => {
						return parseCookies(req)[name];
					},
					set: (name, value) => {
						res.cookie(name, value, {
							path: '/',
							expires: new Date(Date.now() + 3600 * 1000 * 24 * 365),
						});
					},
				},
				tokenGenerator,
			});
		}

		if (!store) {
			store = createStore(initialState, {
				baseUrl: publicRuntimeConfig.browserApiUrl,
				deviceType,
				isMobile: false,
				storage: {
					get: name => {
						return Cookie.get(name);
					},
					set: (name, value) => {
						Cookie.set(name, value);
					},
				},
				tokenGenerator,
			});
		}

		return store;
	};
})();

const MyApp = (props) => {
	const [reduxStore] = useState(
		getStore({
			initialState: props.initialReduxState,
			deviceType: props.deviceType,
		}),
	);

	useEffect(() => {
		const isLoaded = isSessionLoadedSelector(reduxStore.getState());

		if (!isLoaded) {
			reduxStore.dispatch(
				createSession(false, client => {
					if (client) {
						ecommerce.setLogin('login', client.id);
					}
				}),
			);
		}

		reduxStore.dispatch(fetchCurrency());
	}, []);

	const { Component, pageProps, host, errorStatus, hasError, deviceType } = props;
	const store = reduxStore;
	const token = sessionTokenSelector(reduxStore.getState());
	const user = userSelector(reduxStore.getState());

	if (typeof window !== 'undefined') {
		setTimeout(() => {
			settingGlobalParams({
				deliveryUrl: publicRuntimeConfig.deliveryUrl,
				query: Router.query,
				clientIp: pageProps?.clientIp,
			});
		}, 0);
	}

	return hasError ? (
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<NavProvider>
					<Error {...pageProps} statusCode={errorStatus} />
				</NavProvider>
			</ThemeProvider>
		</Provider>
	) : (
		<Provider store={store}>
			<Head />
			<Scripts />
			<ErrorBoundary>
				<HostProvider host={host}>
					<ThemeProvider theme={theme}>
						<ChannelsProvider authUser={user} authToken={token}>
							<EventsProvider>
								<NotificationProvider>
									<NavProvider>
										<Page isMobile={deviceType === 'web-mobile'}>
											{/* <NextNProgress /> */}
											<Component {...pageProps} />
										</Page>
									</NavProvider>
								</NotificationProvider>
							</EventsProvider>
						</ChannelsProvider>
					</ThemeProvider>
				</HostProvider>
			</ErrorBoundary>
		</Provider>
	);
};

MyApp.getInitialProps = async ({ Component, ctx }) => {
	const userAgent =
		typeof window !== 'undefined'
			? window?.navigator?.userAgent
			: ctx.req?.headers['user-agent'] || '';
	const isSafari = userAgent.length > 0 ? /^((?!chrome|android).)*safari/i.test(userAgent) : null;
	const device = deviceDetector.parse(userAgent);
	let deviceType = 'web-desktop';

	if (device.device && device.device?.type === 'smartphone') {
		deviceType = 'web-mobile';
	}

	const reduxStore = getStore({ req: ctx.req, res: ctx.res, deviceType });

	await reduxStore.dispatch(createToken());

	const hasGotIP = hasGotIPSelector(reduxStore.getState());

	let clientIp = '';

	if (!hasGotIP) {
		await reduxStore.dispatch(getIP());
		clientIp = requestIp.getClientIp(ctx.req);
	}

	let pageProps = {};
	let errorStatus;
	let hasError = false;

	if (ctx.err) {
		// eslint-disable-next-line no-console
		console.warn('[ctx error]:', ctx.err);
		errorStatus = ctx.err.statusCode;
	} else {
		errorStatus = ctx.res ? ctx.res.statusCode : null;
	}

	await reduxStore.dispatch(fetchMenu());

	if (Component.getInitialProps) {
		try {
			pageProps = await Component.getInitialProps({ ...ctx, reduxStore });
			if (pageProps.data) {
				pageProps.isSafari = isSafari;
				pageProps.clientIp = clientIp;
			}
		} catch (error) {
			errorStatus = error.statusCode || 500;
			hasError = true;
			if (ctx.res) {
				ctx.res.status(errorStatus);
			}
			// eslint-disable-next-line no-console
			// console.error('[Initial props error]: ', error.message);
		}
	}

	return {
		host: getHost(ctx.req),
		pageProps,
		initialReduxState: reduxStore.getState(),
		hasError: Component === Error || hasError,
		errorStatus,
		deviceType,
	};
};

export default MyApp;
