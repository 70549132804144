/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import { getCurrency } from '@fh-components/fh-js-api-core/store/slices/currencySlice';
import clsx from 'clsx';
import { ActiveLink, Price, ProductFeature, WishlistBtn, LazyPicture, Loader } from '@/components';
import { useWindowSize } from '@/hooks';
import getLinkProps from '@/lib/getLinkProps';
import useLocalStorage from '@/hooks/use-local-storage';
import ecommerce from '@/seo/Ecommerce';
import TextVariants from './components/TextVariants';

import styles from './ProductCard.module.scss';

const AddToCart = dynamic(() => import('../addToCart'), {
	loading: () => <Loader />,
});

const ProductCard = props => {
	const {
		isLast = false,
		dependencies,
		slug: initialSlug,
		brand: initialBrand,
		name: initialName,
		price: initialPrice,
		old_price: initialOldPrice,
		price_diff_percent: initialPriceDiffPercent,
		media: initialMedia,
		external_id,
		quantity: stockQuantity,
		cart_quantity: cartQuantity,
		mod = 'default',
		old_price_reason,
		discount,
		features: initialFeatures,
		loading = false,
		switchable_attributes,
		titleLimit = false,
		images_info,
		isSafari,
		getWishlist,
		sex = null,
		pol = null,
		product_group_id = null,
		categories = [],
		pageName = null,
		name_marketing = null,
		indexCard = 1,
		model = {},
		newCurrency = null,
		gallery,
	} = props;

	const googleSnippets = props?.google_snippet;

	const firstRun = useRef(true);
	const currency = newCurrency ? newCurrency : useSelector(getCurrency);
	const [size, setSize] = useState('');
	const isWishlist = mod === 'wishlist';
	const isCatalog = mod === 'catalog';
	const isMini = mod === 'mini';
	const isCheckout = mod === 'checkout';
	const isViewed = mod === 'viewed';
	const isLook = mod === 'looks';
	const isCollection = mod === 'narrowCollection';
	const isProductSlider = mod === 'productSlider';

	const [_productInfo, setProductInfo] = useLocalStorage('productInfo', null);

	const { isDesktop } = useWindowSize();

	const [activeProductData, setActiveProductData] = useState({
		slug: initialSlug,
		brand: initialBrand,
		name: initialName,
		price: initialPrice,
		old_price: initialOldPrice,
		price_diff_percent: initialPriceDiffPercent,
		media: initialMedia,
		features: initialFeatures,
		switchable_attributes,
		sex,
		pol,
		categories,
		name_marketing,
		product_group_id,
		external_id,
	});

	useEffect(() => {
		if (isWishlist && switchable_attributes && switchable_attributes.length > 0) {
			switchable_attributes.forEach(({ attribute, value }) => {
				if (attribute === 'Размер производителя') {
					setSize(`${value}`);
				}
			});
		}
	}, [isWishlist, switchable_attributes]);

	useEffect(() => {
		if (firstRun.current) {
			firstRun.current = false;
			return;
		}

		const {
			slug,
			brand,
			name,
			price,
			old_price,
			price_diff_percent,
			media,
			features,
			external_id,
			switchable_attributes,
			sex,
			pol,
			categories,
			name_marketing,
			product_group_id,
		} = props;

		setActiveProductData({
			slug,
			brand,
			name,
			price,
			old_price,
			price_diff_percent,
			media,
			features,
			external_id,
			switchable_attributes,
			sex,
			pol,
			categories,
			name_marketing,
			product_group_id,
		});
	}, [props]);

	const [quantity, setQuantity] = useState(+cartQuantity);

	useEffect(() => {
		// if on cart update quantity is out of sync
		if (stockQuantity === cartQuantity && stockQuantity !== quantity) {
			setQuantity(stockQuantity);
		}
	}, [stockQuantity, cartQuantity]);

	const {
		slug,
		brand,
		name,
		price,
		old_price,
		price_diff_percent,
		media,
		features,
	} = activeProductData;

	const mediaFirst = isViewed && gallery ? gallery?.[0] : media?.[0];
	const mediaSecond = isViewed && gallery ? gallery?.[1] : media?.[1];
	const hasImage = !!mediaFirst?.x2;

	const googleSnippetFirst = googleSnippets && googleSnippets.x2 ? googleSnippets : null;

	const pictureClasses = {
		loaded: styles.loaded,
		error: styles.error,
	};
	const inStock = stockQuantity !== 0;

	const [linkHref, linkAs] = getLinkProps({ type: 'product', value: model?.slug ?? initialSlug });

	const colorDeps = useMemo(() => (dependencies && dependencies.length ? dependencies : []), [
		slug,
		dependencies,
	]);

	const hasColorDeps = useMemo(() => colorDeps?.length > 0, [colorDeps]);
	const { alt: imageAlt = '', title: imageTitle = '' } = images_info || {};

	const AddToCartButton = useMemo(() => {
		return (
			<span className={styles.cartBtnContiner}>
				<AddToCart
					productId={external_id ?? model.slug}
					onSuccess={() => {
						const product = activeProductData || {};

						ecommerce.viewItemList(
							[{ ...product, quantity: 1 }],
							currency,
							'Каталог товаров: Избранное',
							'add_to_cart',
							indexCard,
						);
					}}
					inStock={inStock}
					bucketSmall
					productInfo={{
						pageName: 'Каталог товаров: Избранное',
						indexCard,
					}}
					buttonProps={{
						classNames: { general: styles.cartBtn },
						disabled: !!(isWishlist && stockQuantity === 0),
					}}
				/>
			</span>
		);
	}, [colorDeps, slug, activeProductData, stockQuantity, isWishlist, currency]);

	const BucketBlock = useMemo(() => {
		return (
			<span
				role='tab'
				tabIndex={0}
				className={styles.buttonsContainer}
				onClick={e => {
					e.preventDefault();
					e.stopPropagation();
				}}
			>
				{AddToCartButton}
				<WishlistBtn
					product={props}
					large={false}
					border
					getWishlist={isWishlist ? getWishlist : null}
					index={indexCard}
				/>
			</span>
		);
	}, [AddToCartButton, stockQuantity, isWishlist, props]);

	const ParamsBlock = useMemo(() => {
		return (
			<span
				className={clsx(
					{
						[styles.empty]: !inStock,
					},
					styles.params,
				)}
			>
				{!!switchable_attributes?.length && (
					<span className={styles.attributes}>
						{switchable_attributes.map(({ attribute, value }) => (
							<dl className={styles.attribute} key={value}>
								<dt>{attribute}:</dt>
								<dd>{value}</dd>
							</dl>
						))}
					</span>
				)}
				{isCheckout && <span className={styles.quantity}> {quantity} шт. </span>}
			</span>
		);
	}, [inStock, switchable_attributes, quantity, isCheckout]);

	const cardClick = useCallback(() => {
		if (pageName && typeof window !== 'undefined') {
			setProductInfo({
				pageName,
				indexCard,
			});
		}
	}, [pageName, indexCard]);

	return (
		<span className={clsx(styles.main, isCheckout && styles.checkoutMain)}>
			<span className={styles.click} onClick={cardClick}>
				<ActiveLink
					href={{ pathname: linkHref, query: isWishlist ? { variant: external_id } : null }}
					as={{ pathname: linkAs }}
				>
					<a
						className={clsx(
							{
								[styles.isCatalog]: isCatalog,
								[styles.isCheckout]: isCheckout,
								[styles.isMini]: isMini,
								[styles.outOfStock]: !inStock,
							},
							styles.cardWrapper,
							isLast && styles.lastCardWrapper,
						)}
					>
						<header className={styles.header}>
							<span className={styles.img}>
								{mediaFirst && hasImage ? (
									<LazyPicture
										classes={pictureClasses}
										data={mediaFirst}
										snippet={!isSafari ? googleSnippetFirst : null}
										alt={imageAlt ? `${imageAlt} | Фото 1` : 'Фото 1'}
										title={imageTitle}
										isSafari={isSafari}
									/>
								) : (
									<img
										className={clsx(styles.loaded, styles.template)}
										src='/images/svg/pattern-order-picture.svg'
										alt={imageAlt ? `${imageAlt} | Фото 1` : 'Фото 1'}
									/>
								)}
							</span>

							{mediaSecond && (
								<span className={clsx(styles.img, styles.hovered)}>
									<LazyPicture
										classes={pictureClasses}
										data={mediaSecond}
										noImageOnTouch
										alt={imageAlt ? `${imageAlt} | Фото 2` : 'Фото 2'}
										title={imageTitle}
										isSafari={isSafari}
									/>
								</span>
							)}

							<span className={styles.signs}>
								{!(isCheckout || isMini) && !!features?.length && (
									<ul className={styles.featureList}>
										{features.map(({ id, type, label, text }, ind) => (
											<li className={styles.item} key={id || ind}>
												<ProductFeature title={label} styledType={type} text={text} />
											</li>
										))}
									</ul>
								)}
							</span>
							{isCatalog && (
								<WishlistBtn
									product={props}
									large={false}
									border
									isCatalog
									index={indexCard}
									getWishlist={isWishlist ? getWishlist : null}
								/>
							)}
						</header>

						{!(isViewed || isMini) && (
							<>
								<span className={styles.desc}>
									<span className={styles.seoLink} itemProp='name'>
										{brand && <span className={styles.brand}>{brand}</span>}
										<span className={clsx({ [styles.titleLimit]: titleLimit }, styles.title)}>
											{name}
										</span>
									</span>
									<span>
										{inStock ? (
											<span className={!isLook && styles.rowWrap}>
												<span
													className={clsx(
														{ [styles.singleCell]: !price_diff_percent },
														styles.price,
													)}
													itemProp='offers'
													itemType='https://schema.org/Offer'
													itemScope
												>
													<link itemProp='url' href={linkAs} />
													<meta itemProp='availability' content='https://schema.org/InStock' />
													<meta itemProp='priceCurrency' content='BYN' />
													<meta itemProp='price' content={price} />
													{!!price_diff_percent && (
														<ProductFeature title={`${price_diff_percent}%`} styledType='sale' />
													)}
													<Price
														price={price}
														oldPrice={old_price}
														mod='product-card'
														isCatalog={isCatalog}
														isCheckout={isCheckout}
														isLook={isLook}
														oldPriceReason={old_price_reason}
														priceDiscount={discount}
														isCollection={isCollection}
														isProductSlider={isProductSlider}
														isWishlist={isWishlist}
													/>
												</span>
											</span>
										) : (
											<p className={styles.empty}>Нет в наличии</p>
										)}
									</span>
									{isCheckout && ParamsBlock}
								</span>
								{(isCatalog || isWishlist) && (
									<span className={styles.moveable}>
										{hasColorDeps && <TextVariants variants={colorDeps} />}
										{size.length > 0 && <div className={styles.wishlistSize}>{size}</div>}
										{isDesktop && isWishlist && BucketBlock}
									</span>
								)}
							</>
						)}
					</a>
				</ActiveLink>
			</span>
			{isWishlist && !isDesktop && BucketBlock}
		</span>
	);
};

export default ProductCard;
