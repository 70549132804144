import { createSelector } from '@reduxjs/toolkit';

export const isSessionLoadedSelector = createSelector(
	state => state.session.isLoaded ?? false,
	isLoaded => isLoaded,
);

export const sessionTokenSelector = createSelector(
	state => state.session.token ?? null,
	token => token,
);

export const sessionIdSelector = createSelector(
	state => state.session.id ?? null,
	id => id,
);

export const sessionIsSubscribedChannel = createSelector(
	state => state.session.isSubscribedChannel ?? false,
	isSubscribedChannel => isSubscribedChannel,
);
